import { Text } from '../../../../theme/components/Typography';
import { colors } from '../../../../theme';
import { mq } from 'styled-gen';
import { position, rgba } from 'polished';
import styled, { css } from 'styled-components';

const LocationList = styled.ul`
  margin-top: 2rem;

  ${mq.tablet(css`
    margin-top: 3.25rem;
  `)}
`;

const LocationListItem = styled.li``;

const LocationListLabel = styled(Text).attrs({
  isLabel: true,
  n04: true,
  small: true
})`
  padding-bottom: 0.125rem;
  border-bottom: 1px solid ${colors.n035};
`;

const LocationListPlace = styled.div`
  display: block;
  flex-shrink: 0;
  margin-top: 2rem;
  width: 100%;

  ${mq.tablet(css`
    width: 45%;
  `)};
`;

const LocationListPlaceDescription = styled(Text).attrs({
  XSmall: true
})`
  margin-top: 1rem;
`;

const LocationListPlaceLogo = styled.img`
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  width: auto;
`;

const LocationListPlaceLogoWrapper = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  display: inline-block;
  height: 3rem;

  ${mq.tablet(css`
    height: 2rem;

    &:hover {
      opacity: 0.75;
    }
  `)};
`;

const LocationListPlaceName = styled(Text).attrs({
  XSmall: true
})`
  margin-top: 0.75rem;
  line-height: 1.5;

  ${mq.tablet(css`
    margin-top: 1.25rem;
  `)};
`;

const LocationListPlaces = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3.375rem;

  ${mq.tablet(css`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  `)};
`;

const Map = styled.img.attrs({
  src: '/img/map.svg'
})`
  display: none;
  height: auto;
  width: 100%;

  ${mq.tabletLandscape(css`
    display: block;
  `)}
`;

const MapInset = styled.img.attrs({
  src: '/img/map-detail.svg'
})`
  display: block;
  border: solid 4px ${colors.n04};
  border-radius: 50%;
  box-shadow: 0 0 1.25rem ${rgba(colors.n07, 0.16)};
  height: auto;
  margin: auto;

  ${mq.tabletLandscape(css`
    ${position('absolute', '7.5%', null, null, '5%')};

    display: inline-block;
    width: 50%;
  `)}
`;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export {
  LocationList,
  LocationListItem,
  LocationListLabel,
  LocationListPlace,
  LocationListPlaceDescription,
  LocationListPlaceLogo,
  LocationListPlaceLogoWrapper,
  LocationListPlaceName,
  LocationListPlaces,
  Map,
  MapInset,
  MapWrapper
};
