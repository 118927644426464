import { Col, Grid, Row, Section } from '../../../../theme/components';
import { SectionContent, SectionImage } from '../../../../components';
import PropTypes from 'prop-types';
import React from 'react';

export const Hero = props => {
  const {
    image: { url: image },
    ...content
  } = props;

  return (
    <Section mediumPadding mt={4}>
      <Grid>
        <Row reverse>
          <Col mb={{ md: 0, xs: 4 }} md={6} mdOffset={1} xs={12}>
            <SectionImage side="right" splat="mainSplat" src={image} />
          </Col>
          <Col md={5} xs={12}>
            <SectionContent {...content} buttonProps={{ large: true }} />
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Hero.propTypes = {
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string
  }),
  preHeading: PropTypes.string,
  text: PropTypes.any
};
