import { AnimatedEl, AnimatedGroup, SectionContent } from '../../../../components';
import { Grid, Section } from '../../../../theme/components';
import {
  LocationList,
  LocationListItem,
  LocationListLabel,
  LocationListPlace,
  LocationListPlaceDescription,
  LocationListPlaceLogo,
  LocationListPlaceLogoWrapper,
  LocationListPlaceName,
  LocationListPlaces,
  Map,
  MapInset,
  MapWrapper
} from './MapSection.style';
import { PlainText } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';

export const MapSection = props => {
  const { items, ...content } = props;

  return (
    <Section mediumPadding>
      <Grid>
        <AnimatedGroup>
          <AnimatedEl md={5} mdOffset={1} xs={12}>
            <MapWrapper>
              <Map />
              <MapInset />
            </MapWrapper>
          </AnimatedEl>
          <AnimatedEl animation={{ from: 'right' }} md={5} mdOffset={1} pt={2} xs={12}>
            <SectionContent {...content} />
            <LocationList>
              {items.map(({ primary: { locationName }, items: locations }, index) => (
                <LocationListItem key={index}>
                  <LocationListLabel>{locationName}</LocationListLabel>
                  <LocationListPlaces>
                    {locations.map(({ description, entity, logo: { url: logo }, url }, locationIndex) => (
                      <LocationListPlace key={`${index}-${locationIndex}`}>
                        <LocationListPlaceLogoWrapper href={url}>
                          <LocationListPlaceLogo src={logo} />
                        </LocationListPlaceLogoWrapper>
                        {!!entity && (
                          <LocationListPlaceName>
                            <PlainText content={entity} />
                          </LocationListPlaceName>
                        )}
                        {!!description && <LocationListPlaceDescription>{description}</LocationListPlaceDescription>}
                      </LocationListPlace>
                    ))}
                  </LocationListPlaces>
                </LocationListItem>
              ))}
            </LocationList>
          </AnimatedEl>
        </AnimatedGroup>
      </Grid>
    </Section>
  );
};

MapSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          entity: PropTypes.string,
          logo: PropTypes.shape({
            url: PropTypes.string
          }),
          url: PropTypes.string
        })
      ),
      primary: PropTypes.shape({
        locationName: PropTypes.string
      })
    })
  ),
  preHeading: PropTypes.string,
  text: PropTypes.any
};
