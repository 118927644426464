import { AnimatedEl, AnimatedGroup, SectionHeading, SectionImage } from '../../../../components';
import { Grid, Icon, Section } from '../../../../theme/components';
import { ProjectItem, ProjectLink, ProjectList, ProjectsItem, ProjectsList } from './Projects.style';
import { RichText } from 'prismic-react-tools';
import { Text } from '../../../../theme/components/Typography';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const Projects = props => {
  const {
    list,
    photo: { url: image },
    ...heading
  } = props;

  return (
    <Section id="projects">
      <SectionHeading {...heading} colWidth={8} />
      <Grid>
        <AnimatedGroup center="xs">
          <AnimatedEl animation={{ from: 'left' }} mb={{ md: 0, xs: 4 }} md={5} mdOffset={1} xs={12}>
            <SectionImage splat="splat1" src={image} />
          </AnimatedEl>
          <AnimatedEl md={5} mdOffset={1} mt={{ md: 0, sm: 2, xs: 1 }} sm={9} xs={12}>
            <ProjectsList>
              {list.map(({ primary: { heading }, items }, index) => (
                <ProjectsItem key={index}>
                  <Text md n07 semibold>
                    {heading}
                  </Text>
                  {items.map(({ description, file, fileLabel }, itemIndex) => (
                    <ProjectList key={itemIndex}>
                      <ProjectItem>
                        <Text as="div">
                          <RichText content={description} />
                        </Text>
                        {get(file, 'url') && (
                          <ProjectLink href={file.url}>
                            <Icon icon="fileBlankO" />
                            <span>{fileLabel}</span>
                          </ProjectLink>
                        )}
                      </ProjectItem>
                    </ProjectList>
                  ))}
                </ProjectsItem>
              ))}
            </ProjectsList>
          </AnimatedEl>
        </AnimatedGroup>
      </Grid>
    </Section>
  );
};

Projects.propTypes = {
  heading: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.any,
          file: PropTypes.shape({
            url: PropTypes.string
          }),
          fileLabel: PropTypes.string
        })
      ),
      primary: PropTypes.shape({
        heading: PropTypes.string
      })
    })
  ),
  photo: PropTypes.shape({
    url: PropTypes.string
  }),
  preHeading: PropTypes.string,
  text: PropTypes.any
};
