import { AnimatedEl, AnimatedGroup, SectionContent } from '../../../../components';
import { Col, CurvedContainer, Grid, Icon, Section } from '../../../../theme/components';
import {
  CurrentPositions,
  CurrentPositionsLabel,
  CurrentPositionsLink,
  Member,
  MemberName,
  MemberPhoto,
  MemberPhotoButton,
  MemberPhotoImage,
  MemberPhotoOverlay,
  MemberPosition,
  Members
} from './Team.style';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const Team = props => {
  const { availablePositions, availablePositionsLabel, hiringCta, members, ...content } = props;

  const positions = availablePositions.reduce(
    (prev, current) => (get(current, 'pdfFile.url') ? [...prev, current] : prev),
    []
  );

  return (
    <CurvedContainer as="section" id="team" mt={{ md: 8, xs: 4 }} topCurve topCurveInverted>
      <Section as="div" grayGradient>
        <Grid>
          <AnimatedGroup>
            <AnimatedEl animation={{ from: 'left' }} md={4} xs={12}>
              <SectionContent {...content} buttonProps={{ large: true }}>
                {!isEmpty(positions) && (
                  <CurrentPositions>
                    <CurrentPositionsLabel>{availablePositionsLabel}</CurrentPositionsLabel>
                    {positions.map(({ label, pdfFile }, index) => (
                      <CurrentPositionsLink href={pdfFile?.url} key={index}>
                        <Icon icon="filePdfO" />
                        {label}
                      </CurrentPositionsLink>
                    ))}
                  </CurrentPositions>
                )}
              </SectionContent>
            </AnimatedEl>
            <Col md={8} xs={12}>
              <AnimatedGroup parent={Members} row={false} stagger={0.1}>
                {[...members, ...availablePositions].map(
                  ({ label, linkedin, name, pdfFile, photo, position }, index) => (
                    <Member key={index}>
                      <MemberPhoto>
                        <MemberPhotoImage photo={get(photo, 'url')}>
                          {photo ? <MemberPhotoOverlay /> : <div>?</div>}
                        </MemberPhotoImage>
                        {(!!linkedin || get(pdfFile, 'url')) && (
                          <MemberPhotoButton href={linkedin ? linkedin : get(pdfFile, 'url')}>
                            <Icon icon={linkedin ? 'linkedin' : 'filePdfO'} />
                          </MemberPhotoButton>
                        )}
                      </MemberPhoto>
                      <MemberName>{name || hiringCta}</MemberName>
                      <MemberPosition>{position || label}</MemberPosition>
                    </Member>
                  )
                )}
              </AnimatedGroup>
            </Col>
          </AnimatedGroup>
        </Grid>
      </Section>
    </CurvedContainer>
  );
};

Team.propTypes = {
  availablePositions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      pdfFile: PropTypes.shape({
        url: PropTypes.string
      })
    })
  ),
  availablePositionsLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  heading: PropTypes.string,
  hiringCta: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      linkedin: PropTypes.string,
      name: PropTypes.string,
      photo: PropTypes.shape({
        url: PropTypes.string
      }),
      position: PropTypes.string
    })
  ),
  preHeading: PropTypes.string,
  text: PropTypes.any
};
