import { Text } from '../../../../theme/components/Typography';
import { colors, fonts } from '../../../../theme';
import { linearGradient, position, size } from 'polished';
import { mq } from 'styled-gen';
import styled, { css } from 'styled-components';

const CurrentPositions = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${mq.tabletLandscape(css`
    text-align: left;
  `)}
`;

const CurrentPositionsLabel = styled(Text).attrs({
  XSmall: true,
  isLabel: true,
  n04: true
})`
  margin-bottom: 1.25rem;
  text-align: center;

  ${mq.tabletLandscape(css`
    text-align: left;
  `)}
`;

const CurrentPositionsLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  align-items: center;
  color: ${colors.n06};
  display: flex;
  font-size: ${fonts.size('base')};
  justify-content: center;

  svg {
    ${size('1.25rem')};

    margin-right: 1rem;
  }

  & + & {
    margin-top: 1rem;
  }

  ${mq.tabletLandscape(css`
    justify-content: flex-start;

    &:hover {
      color: ${colors.p06};
    }
  `)}
`;

const Members = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1rem;
  margin-top: 2rem;

  ${mq.tablet(css`
    justify-content: space-between;
  `)}

  ${mq.tabletLandscape(css`
    margin-top: -1rem;
  `)}
`;

const Member = styled.div`
  padding: 1.75rem;
  width: 80%;

  ${mq.tablet(css`
    padding: 1.75rem;
    width: 33%;
  `)}

  &:last-of-type {
    ${mq.tablet(css`
      margin-right: auto;
    `)}
  }
`;

const MemberPhoto = styled.div`
  padding-top: 100%;
  position: relative;
`;

const MemberPhotoImage = styled.div`
  ${position('absolute', 0)};

  align-items: center;
  border-radius: 50%;
  background-color: ${colors.n02};
  background-image: url("${({ photo }) => photo}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${colors.n035};
  display: flex;
  font-size: 6rem;
  font-weight: ${fonts.weights.extrabold};
  justify-content: center;
  overflow: hidden;
`;

const MemberPhotoOverlay = styled.div`
  ${position('absolute', 0)};
  ${linearGradient({
    colorStops: [`${colors.p04} 0%`, `${colors.i06} 100%`],
    fallback: colors.p06,
    toDirection: 'to bottom'
  })}

  opacity: 0.6;
`;

const MemberPhotoButton = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  ${position('absolute', null, 0, 0, null)};
  ${size('4rem')};

  align-items: center;
  background-color: ${colors.n01};
  border-radius: 50%;
  color: ${colors.n035};
  display: flex;
  justify-content: center;

  svg {
    ${size('1.75rem')};
  }

  ${mq.tabletLandscape(css`
    &:hover {
      background-color: ${colors.p06};
      color: ${colors.n01};
    }
  `)}
`;

const MemberName = styled(Text).attrs({
  center: true,
  mediumLarge: true,
  n07: true,
  semibold: true
})`
  margin-top: 1rem;

  ${mq.tablet(css`
    margin-top: 2rem;
  `)}
`;

const MemberPosition = styled(Text).attrs({
  center: true,
  n06: true,
  small: true
})`
  ${mq.tablet(css`
    margin-top: 0.5rem;
  `)}
`;

export {
  CurrentPositions,
  CurrentPositionsLabel,
  CurrentPositionsLink,
  Member,
  MemberName,
  MemberPhoto,
  MemberPhotoButton,
  MemberPhotoImage,
  MemberPhotoOverlay,
  MemberPosition,
  Members
};
