import { AnimatedGroup, SectionHeading } from '../../../../components';
import { Col, Div, Grid, Row, Section } from '../../../../theme/components';
import { get } from 'lodash';
import { mq } from 'styled-gen';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Img = styled.img`
  width: 100%;
  max-width: 11.25rem;
  height: auto;
  max-height: 7.5rem;
  vertical-align: middle;

  ${mq.tablet(css`
    width: auto;
  `)}
`;

const List = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;

  ${mq.tabletLandscape(css`
    margin: -2.5rem;
  `)};

  & > div {
    width: 100%;
  }

  .file-link {
    display: inline-block;
    padding: 1rem;
    width: 50%;

    ${mq.tablet(css`
      padding: 2.5rem;
      width: 100%;
      max-width: 200px;
    `)}
  }

  .file-link {
    &:hover {
      opacity: 0.75 !important;
    }
  }
`;

export const Certificates = props => {
  const { items, ...heading } = props;

  return (
    <Section id="certificates">
      <SectionHeading {...heading} colWidth={12} />
      <Grid>
        <Row>
          <Col xs={12}>
            <List>
              <AnimatedGroup row={false} stagger={0.1}>
                {items.map(({ file, image, url }, index) => (
                  <Div
                    as={!!get(file, 'url') || !!url ? 'a' : 'div'}
                    className="file-link"
                    href={get(file, 'url') || url}
                    key={index}
                    target={!!get(file, 'url') || !!url ? 'blank' : undefined}
                  >
                    <Img src={get(image, 'url')} />
                  </Div>
                ))}
              </AnimatedGroup>
            </List>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Certificates.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      }),
      image: PropTypes.shape({
        url: PropTypes.string
      }),
      url: PropTypes.string
    })
  ),
  preHeading: PropTypes.string
};
