import { colors } from '../../../../theme';
import { size } from 'polished';
import styled from 'styled-components';

const ProjectItem = styled.li``;

const ProjectLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  align-items: center;
  color: ${colors.n06};
  display: inline-flex;
  margin-top: 1rem;

  svg {
    ${size('1.5rem')};
  }

  span {
    margin-left: 0.875rem;
  }

  &:hover {
    color: ${colors.n08};
  }
`;

const ProjectList = styled.ul`
  &:first-of-type {
    border-top: 1px solid ${colors.n035};
    margin-top: 0.5rem;
    padding-top: 1.5rem;
  }

  & + & {
    margin-top: 2rem;
  }
`;

const ProjectsItem = styled.li`
  & + & {
    margin-top: 2.75rem;
  }
`;

const ProjectsList = styled.ul`
  text-align: left;
`;

export { ProjectItem, ProjectLink, ProjectList, ProjectsItem, ProjectsList };
