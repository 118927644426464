import { Certificates, Hero, MapSection, Projects, Team } from './components';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const About = props => {
  const {
    pageContext: { document }
  } = props;

  const certificates = extractFromCamelCase(document, 'certificates');
  const hero = extractFromCamelCase(document, 'hero');
  const location = extractFromCamelCase(document, 'location');
  const team = extractFromCamelCase(document, 'team');
  const projects = extractFromCamelCase(document, 'projects');

  return (
    <>
      <Hero {...hero} />
      <MapSection {...location} />
      <Team {...team} />
      <Certificates {...certificates} />
      <Projects {...projects} />
    </>
  );
};

About.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(About, { withTestimonials: true });
